.ticketDetails-container {
  width: 100%;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}

.no-data {
  width: 99.5%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #b8bdc3;
}

.ticketDetails-box {
  padding: 20px;
  border-radius: 5px;
}

.ticketDetails-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  position: relative;
}

.filter-dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
}

.filter-dropdown input,
.filter-dropdown select {
  display: block;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
}

.ticketDetails-tabs {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 10px;
  border-bottom: 2px solid black;
}

.ticketDetails-tabs button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.ticketDetails-tabs button.active {
  background-color: #0e2741;
  color: #fff;
}

.ticketDetails-inputgroup {
  width: 35%;
}

.ticketDetails-inputgroup button {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.ticketDetails-table-container {
  overflow-x: auto;
  background-color: #ebf1f2;
  border-radius: 5px;
  padding: 20px;
  padding-top: 8px;
  margin-left: 24px;
}

.ticketDetails-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.ticketDetails-tablehead th {
  background-color: #4695b8;
  color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
}

.ticketDetails-tablebody td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.ticketDetails-tablebody td button {
  background-color: #4695b8;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}

h2 {
  margin-top: 20px;
}