@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.login-container {
  display: flex;
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

.welcome-page {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit:fill;
  z-index: -1;
}

.header {
  position: absolute;
  top: 10px;
  left: 10px;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.logo svg {
  margin-right: 10px;
}

.content {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.content h1 {
  margin: 0;
  font-size: 36px;
}

.content p {
  margin: 10px 0 0;
  font-size: 18px;
}

.login-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.login-head {
  text-align: center;
}

.login-head h2 {
  font-size: 24px;
}

.login-form {
  width: 100%;
  max-width: 400px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-but {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  background-color: #00bfa6;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 25px;
}

.login-but:hover {
  background-color: #00997c;
}

@media (max-width :750px) {
  /* .welcome-page{
    display: none;
  } */

}
