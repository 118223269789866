@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.formerdetail-container {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.formerdetail-box {
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  margin-bottom: 10px;
}

.formerdetail-ticket {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.formerdetail-ticket button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid #4695b8;
  border-radius: 4px;
}

.formerdetail-ticket button svg {
  margin-right: 8px;
}

.formerdetail-details {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #dcecf9;
  margin-bottom: 20px;
}

.formerdetails-head h3 {
  font-size: larger;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

.formerdetail-info {
  padding: 1em 2em ;
  width: 70%;
}

.formerdetail-info table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.formerdetail-info td {
  font-size: larger;
  padding: 8px;
  font-weight: 300;
  font-size: 16px;
}

.formerdetail-info td:first-child {
  font-weight: 500;
}

.formerdetail-cow {
  margin-top: 20px;
}

.formerdetail-cow table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}

.formerdetail-cow th,
.formerdetail-cow td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  background-color: #ebf1f2;
  height: 50px;
}

.formerdetail-cow th {
  background-color: #4695b8;
  color: white;
  font-weight: 400;
}

.formerdetail-cow table tbody tr td button {
  background-color: #4695b8;
  padding: 10px;
  border-radius: 5px;

  color: #fff;
}

.cow-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 500px;
  padding: 20px;
}

.cow-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cow-header h2 {
  margin: 0;
}

.cow-close {
  cursor: pointer;
  font-size: 1.5em;
}

.cow-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.cow-table th,
.cow-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.cow-table th {
  background-color: #4695b8;
  color: white;
}

.cow-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.overlay.active {
  display: block;
}


.filter-controls {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.filter-controls {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.filter-icon {
  font-size: 24px;
  cursor: pointer;
}

.filter-section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.close-icon {
  font-size: 24px;
  cursor: pointer;
}