@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.formerservice-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  height: 100%;
  width: 100%;
  position: relative;
}

.formerservice-box {
  width: 90%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.formerservice-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.formerservice-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.formerservice-details-page {
  width: 80%;
  display: flex;
  justify-content: space-around;
  /* Spaces items evenly */
  align-items: center;
  /* Centers content vertically */
}

.formerservice-details-vlcc button {
  background-color: #000;
  color: #fff;
  margin: 10px;
  border-radius: 5px;
  padding: 5px;
}
.formerservice-service {
  padding: 10px 50px;
  background-color: #dcecf9;
  border-radius: 10px;
  font-size: large;
}
.formerservice-inputradio div {
  margin: 35px 0;
}

.formerservice-inputtext,
.formerservice-inputradio,
.formerservice-inputtextarea {
  margin-bottom: 20px;
}

.formerservice-inputtext select,
.formerservice-inputtextarea textarea {
  width: 80%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.formerservice-inputtext select option {
  padding: 50px;
  font-size: 1.2em;
  height: 50px;
}

.formerservice-inputradio div {
  margin-bottom: 10px;
}

.formerservice-inputradio label {
  margin-left: 5px;
}

.formerservice-button {
  padding: 20px;
  text-align: right;
}

.text-bold {
  font-weight: bold;
}

.formerservice-button button {
  padding: 10px 20px;
  border: none;
  background-color: #00bfa5;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  width: 15%;
  height: 50px;
  margin-right: 50px;
}

.formerservice-button button:hover {
  background-color: #00897b;
}

.formerservice-doctor-popup {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 90%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

.formerservice-doctor-popup .formerservice-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4695b8;
  color: white;
  padding: 2px 10px;
  border-radius: 4px 4px 0 0;
}

.formerservice-doctor-popup .formerservice-head h3 {
  margin: 0;
}

.formerservice-doctor-popup .formerservice-head svg {
  cursor: pointer;
}

.formerservice-doctor-popup .formerservice-body {
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
}

.formerservice-doctor-popup .formerservice-body table {
  width: 100%;
  border-collapse: collapse;
}

.formerservice-doctor-popup .formerservice-body th,
.formerservice-doctor-popup .formerservice-body td {
  padding: 10px;
  border: 1px solid #ddd;
}

.formerservice-doctor-popup .formerservice-body th {
  background-color: #f2f2f2;
}

.formerservice-doctor-popup .formerservice-body td {
  text-align: center;
}

.formerservice-doctor-but {
  width: 100%;
}

.formerservice-doctor-but button {
  width: 20%;
  margin-top: 15px;
  height: 40px;
  background-color: #00897b;
  color: #fff;
  border-radius: 5px;
  margin-left: calc(80%-50px);
}
.formerservice-feed1 {
  /* display: block;  */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 90%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}
.formerservice-feed-head1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #dcecf9;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
}
.formerservice-feed {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: #dcecf9;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: slideIn 0.5s forwards;
}

.formerservice-feed-head {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcecf9;
  color: #000;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
}

.formerservice-feed-head h2 {
  margin: 0;
}

.formerservice-feed-head svg {
  cursor: pointer;
}

.formerservice-feed-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formerservice-feed-details h3,
.formerservice-feed-details p {
  margin: 5px;
}

.formerservice-feed-details,
.formerservice-feed-table,
.formerservice-feed-footer {
  padding: 20px;
  background-color: #dcecf9;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
}

.formerservice-feed-table table {
  width: 100%;
  border-collapse: collapse;
}

.formerservice-feed-table th,
.formerservice-feed-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.formerservice-feed-table th {
  background-color: #4695b8;
}

.formerservice-feed-table td {
  text-align: center;
}

.formerservice-feed-table button {
  padding: 10px 20px;
  border: none;
  background-color: #00bfa5;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.formerservice-feed-footer {
  display: flex;
  justify-content: space-between;
}

.formerservice-feed-footer button {
  padding: 10px 20px;
  border: none;
  background-color: #00bfa5;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.formerservice-feed-footer button:hover {
  background-color: #00897b;
}

.formerservice-conf-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formerservice-conf-footer button {
  padding: 10px 20px;
  border: none;
  background-color: #00bfa5;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 25px;
}

.showdoctic {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

.showdoctic-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4695b8;
  color: white;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
}

.showdoctic-head h2 {
  margin: 0;
}

.showdoctic-head svg {
  cursor: pointer;
}

.showdoctic-body {
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
}

.showdoctic-body h3 {
  margin: 0;
}

.formerservice-feed-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formerservice-feed-table img {
  width: 100px;
  height: 100px;
}

.formerservice-service {
  position: relative;
  overflow: hidden; /* Ensure the sliding effect stays within bounds */
  min-height: 10vh;
  margin-bottom: 2em;
}

.showvet {
  position: absolute;
  top: 0;
  left: 100%; /* Start the element off-screen to the right */
  width: 100%;
  height: 100%;
  background-color: #dcecf9;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: slideIn 0.5s forwards; /* Apply the sliding animation */
}

@keyframes slideIn {
  0% {
    left: 100%; /* Start position: off-screen to the right */
  }
  100% {
    left: 0; /* End position: fully visible */
  }
}

.showvet-head {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcecf9;
  color: #000;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
}

.showvet-body {
  flex-grow: 1;
  padding: 20px;
  background-color: #dcecf9;
}
.showvet-inputtext,
.showvet-radio,
.showvet-inputtextarea {
  margin-bottom: 20px;
}

.showvet-inputtext label,
.showvet-radio label,
.showvet-inputtextarea label {
  display: block;
  margin-bottom: 5px;
  color: #000;
}

.showvet-inputtext select,
.showvet-inputtextarea textarea {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.showvet-radio .radio-value {
  display: flex;
  gap: 20px;
  align-items: center;
}

.showvet-radio input[type="radio"] {
  margin-right: 5px;
}

.showvet-inputtextarea textarea {
  height: 50px;
  width: 80%;
  resize: none;
}

.shoevet-but {
  text-align: right;
  margin-top: 20px;
}

.shoevet-but button {
  padding: 10px 20px;
  background-color: #88c6ed; /* Button background color matching the image */
  border: 2px solid #4695b8;
  border-radius: 8px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.shoevet-but button:hover {
  background-color: #4695b8; /* Darker blue on hover */
  color: #fff;
}
.showvet-footer {
  padding: 10px;
  text-align: center;
}

.showvet h2 {
  margin: 0;
}

.formerservice-doctor {
  position: absolute;
  top: 0;
  left: 100%; /* Start the element off-screen to the right */
  width: 100%;
  height: 100%;
  background-color: #dcecf9;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: slideIn 0.5s forwards; /* Apply the sliding animation */
}

@keyframes slideIn {
  0% {
    left: 100%; /* Start position: off-screen to the right */
  }
  100% {
    left: 0; /* End position: fully visible */
  }
}

.formerservice-doctor-head {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcecf9;
  color: #000;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
}

.formerservice-body {
  flex-grow: 1;
  padding: 20px;
  background-color: #dcecf9;
}
.formerservice-body {
  padding: 20px;
}

.formerservice-body table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.formerservice-body thead {
  background-color: #f2f2f2;
}

.formerservice-body th,
.formerservice-body td {
  padding: 12px;
  border: 1px solid #ddd;
}

.formerservice-body th {
  background-color: #4695b8;
  color: white;
}
.formerservice-body td {
  background-color: #fff;
}
.formerservice-body input[type="radio"] {
  margin: 5px;
}

.formerservice-doctor-but {
  text-align: right;
  margin-top: 20px;
}

.formerservice-body button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.formerservice-body button:hover {
  background-color: #45a049;
}

.formerservice-doctor-but {
  text-align: right;
  margin-top: 20px;
}

.formerservice-doctor-but button {
  background-color: #88c6ed; /* Button background color matching the image */
  border: 2px solid #4695b8;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 6px 18px !important;
}

.formerservice-doctor-but button:hover {
  background-color: #4695b8; /* Darker blue on hover */
  color: #fff;
}

.loan-container {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: #dcecf9;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: slideIn 0.5s forwards;
}
.loan-container-head {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcecf9;
  color: #000;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
}
.loan-container-body {
  flex-grow: 1;
  padding: 20px;
  background-color: #dcecf9;
}

.loan-input-container label {
  font-size: 18px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #000;
}

.loan-input-container textarea {
  width: 90%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}
.loan-container-but {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin-right: 50px;
  margin-top: 50px;
}

.loan-container-but button {
  background-color: #005792;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.loan-container-but button:hover {
  background-color: #00406b;
}


