.search1{
  display: flex;
  justify-content: right;
  width: 80%;
}

.search{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0e2741 ;
  width: 100%;
}

/*  */
.manage{
  color: #fff;
}


.drop {
  display: flex;
  align-items: center;
  position: relative; 
  left: 1090px;
}

.drop h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
}

.drop .manage-accounts-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.drop .arrow-drop-down-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}