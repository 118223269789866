.main-box {
  display: flex;
  width: 100%;
}

.main-nav{
  height: 100%;
  position: fixed;
  z-index: 0;
}

.main-content{
  width: 100%;
  margin-left: 250px;
}
