@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.formerid-containter {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  align-items: center;
}

.formerdemo{
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formerid-box{
  -webkit-box-shadow: inset 2px -2px 69px -62px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 2px -2px 69px -62px rgba(66, 68, 90, 1);
  box-shadow: inset 2px -2px 69px -62px rgba(66, 68, 90, 1);
}

.formerid-box input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  outline: none;
  font-size: 16px;
  flex: 1;
  margin-bottom: 25px;
}

.formerid-but button {
  padding: 10px 20px;
  background-color: #06ad9d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}
